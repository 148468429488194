<template>
  <div class="preview-products" :class="{ 'preview-products--admin-mobile': show_mobile }">
    <p class="preview-products__title">{{ correct_translations.random_items }}</p>
    <transition name="slide-products">
      <div v-if="products_updated" class="preview-products__wrapper">
        <router-link
          v-for="(product, ind) in get_products"
          :key="ind"
          class="preview-products__product"
          :event="admin_imported ? '' : 'click'"
          :to="create_correct_product_url(product, false)"
          itemprop="link"
        >
          <div v-if="!product.id" class="element-loading" />
          <v-lazy-image
            v-else
            :src="choose_img_to_show_according_to_viewport(product)"
            :src-placeholder="default_image_src"
            alt="Product image"
            itemprop="image"
          />
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import { CLIENT_STORE } from "../../constants/other"
import { ARE_ANY_PRODUCTS_BEING_FETCHED, FETCH_PRODUCTS_BY_IDS } from "../../stores/Client/constants"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import { image_dimension_names } from "../../../../data/other_constants"
import create_correct_product_url from "../../methods/create_correct_product_url"
import { default_image_src } from "../../../Shared/constants/other"
import { CONFIGURATION_STORE } from "../../../Admin/constants/others_constants"
import { default_language } from "../../../../../functions/constans/other"

export default {
  props: {
    product_ids: {
      type: Array,
      required: true
    },
    admin_imported: {
      type: Boolean,
      required: true
    },
    show_mobile: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    VLazyImage
  },
  data() {
    return {
      products_updated: true,
      default_image_src
    } 
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "products",
      "translations"
    ]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    ...mapState(CONFIGURATION_STORE, {
      admin_translations: "translations"
    }),
    correct_translations() {
      const admin_translation = this.admin_translations?.[default_language] ||
        this.admin_translations[Object.keys(this.admin_translations)[0]]

      return (this.admin_imported ? admin_translation : this.translations) || {}
    },
    missing_products() {
      return this.product_ids?.filter(id =>
        !this.products?.find(({ id: product_id }) => product_id === id)
      )
    },
    get_products() {
      return [
        ...(this.products || [])?.filter(
          product => this.product_ids?.find(id => product?.id === id) // Products that are already fetched
        ),
        ...(this.still_fetching_products ? this.missing_products?.map(_ => ({})) : []) // Products that have not yet been fetched
      ].sort((a = {}, b = {}) => {
        if (a.images && !b.images) return -1
        if (b.images && !a.images) return 1
        if (b.images && a.images) return 0

        return -1
      })
    }
  },
  watch: {
    product_ids(val) {
      this.products_updated = false
      this.fetch_products({ ids: this.missing_products })

      if (val.length) setTimeout(() => {
        this.products_updated = true
      }, 200);
    }
  },
  mounted() {
    this.fetch_products({ ids: this.missing_products })
  },
  methods: {
    ...mapActions(CLIENT_STORE, {
      fetch_products: FETCH_PRODUCTS_BY_IDS
    }),
    create_correct_product_url,
    choose_img_to_show_according_to_viewport(product_data) {
      return get_correct_first_image(
        product_data,
        image_dimension_names.mobile
      )
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;
  @use "../../../../styles/element_loading.scss";
  @use "../../../../styles/slide_in_animation" as *;

  .preview-products {
    @media (max-width: $tablet) {
      position: absolute;
      top: 0;
      left: $spacing--normal;
      right: $spacing--normal;
      height: 100%;
    }

    &--admin-mobile {
      position: absolute;
      top: 0;
      left: $spacing--normal;
      right: $spacing--normal;
      height: 100%;


      .preview-products {
        &__wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          row-gap: 20px;
          white-space: normal;
          overflow-x: hidden;
          overflow-y: scroll;
          padding-right: 0;
        }

        &__product {
          height: unset;
          width: unset;
          margin: 0;

          &:after {
            display: block;
            content: "";
            padding-bottom: 100%;
          }

          &:hover {
            img {
              transform: translateY(-50%) translateX(-50%);
            }
          }

          img, div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
          }
        }
      }

      &-mobile {

      }
    }

    &__wrapper {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      width: 100%;
      padding-right: 20px;

      @media (max-width: $tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        white-space: normal;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 0;
      }
    }

    &__title {
      position: relative;
      margin: 0 0 10px 0;
      color: var(--background_heavy) !important;
      opacity: 0.5;
      overflow: hidden;
      font-size: 12px;

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background: var(--background_heavy);
        top: 8px;
        margin-left: 10px;
      }
    }

    &__product {
      position: relative;
      display: inline-block;
      height: calc(#{$menu-contents-height} - 40px - 30px);
      width: calc(#{$menu-contents-height} - 40px - 30px);
      margin-right: 20px;
      overflow: hidden;
      cursor: pointer;
      clip-path: polygon(14% 0, 80% 0%, 100% 0, 100% 86%, 86% 100%, 20% 100%, 0 100%, 0 14%);
      border-radius: $border-radius;

      @media (max-width: $tablet) {
        height: unset;
        width: unset;
        margin: 0;

        &:after {
          display: block;
          content: "";
          padding-bottom: 100%;
        }
      }

      &:hover {
        img {
          transform: scale(1);
        }
      }

      &:last-child {
        margin-right: 0;
      }

      img, div {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
        transform: scale(1.2);
        transition: $default-transition;

        @media (max-width: $tablet) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
  }

  .slide-products-enter-active {
    animation: slide-in .3s;
  }
  .slide-products-leave-active {
    animation: slide-in .3s reverse;
  }
</style>
