<template>
  <div
    class="mobile-menu-toggle"
    id="mobile-menu-toggle"
    @click="$emit('toggle_mobile_menu')"
  >
    <span
      :class="{
        'mobile-menu-toggle__x--open' : !open_mobile_menu && !first_load,
        'mobile-menu-toggle__x--close' : open_mobile_menu && !first_load
      }"
      class="mobile-menu-toggle__x"
    >
      <div />
      <div />
      <div />
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { SHARED_STORE } from "../../constants/other"

export default {
  props: {
    open_mobile_menu: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "first_load",
    ]),
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;

  .mobile-menu-toggle {
    display: inline-flex;
    width: $menu-height;
    height: $menu-height;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    &__x {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 30px;
      vertical-align: middle;
      cursor: pointer;

      &--close {
        div {
          transform: translateY(-50px);
          animation: Menu-line-disappear 600ms cubic-bezier(0, 0, 0.02, 0.99);

          &:nth-child(2) {
            transform: rotate(45deg);
            animation: Rotate-menu-line-right 400ms ease-out;
          }

          &:last-child {
            top: 8px !important;
            transform: rotate(-45deg);
            animation: Rotate-menu-line-left 400ms ease-out;
          }
        }
      }

      &--open {
        div {
          animation: Menu-line-appear 600ms cubic-bezier(0, 0, 0.02, 0.99);

          &:nth-child(2) {
            top: 5px;
            animation: Rotate-menu-line-back-left 400ms ease-out;
          }

          &:last-child {
            top: 9px;
            animation: Rotate-menu-line-back-right 400ms ease-out;
          }
        }
      }

      div {
        position: absolute;
        top: 1px;
        width: 100%;
        height: 3px;
        background-color: var(--item_name_color);
        border-radius: 1px;

        &:nth-child(2) {
          top: 8px;
        }

        &:last-child {
          top: 15px;
        }
      }
    }
  }

  @keyframes Menu-line-disappear {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes Menu-line-appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes Rotate-menu-line-right {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes Rotate-menu-line-back-left {
    0% {
      transform: rotate(45deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes Rotate-menu-line-left {
    0% {
      top: 9px;
      transform: rotate(0);
    }

    100% {
      top: 8px;
      transform: rotate(-45deg);
    }
  }

  @keyframes Rotate-menu-line-back-right {
    0% {
      transform: rotate(-45deg);
    }

    100% {
      transform: rotate(0);
    }
  }

</style>
