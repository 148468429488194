<template>
  <section class="mobile-menu__wrapper" :class="{ 'mobile-menu__wrapper--admin': admin_imported }">
    <transition name="mobile-menu-slide-animation">
      <div
        v-if="show"
        class="mobile-menu"
        :class="{
          'mobile-menu--client': !is_marketplace_environment,
          'mobile-menu--admin': admin_imported,
        }"
      >
        <div v-if="enable_search" class="search" :class="{ 'search--searching': searching }">
          <input
            type="text"
            name="search"
            id="search"
            :placeholder="correct_search_placeholder"
            v-model="query"
            @input="search_products"
          />
          <div class="search-icon" @click="stop_searching"><close_icon v-if="searching"/><search_icon v-else /></div>
          <div v-if="searching" class="search-results">
            <router-link
              v-for="({ id, categories = [], images, variants, link, ...rest }, index) in found_products"
              :key="`search-product-${index}`"
              :to="link || `/products/${categories.join('/')}/${id}`"
              :disabled="!id && !link && admin_imported"
              :event="((id || link) && !admin_imported) ? 'click' : ''"
              @click.native="$emit('handle_click')"
            >
              <div
                class="search-result"
                :class="{ 'element-loading': !id }"
              >
                <img v-if="images" :src="get_image_src(images[0])" :alt="`${get_correct_translation_value(rest, 'title', [selected_language])} image`">
                <div v-if="id">
                  <p>{{ get_correct_translation_value(rest, "title", [selected_language]) }}</p>
                  <p>
                    <span v-if="is_currency_symbol(currency_sign)">{{ currency_sign }}</span>
                    {{ get_correct_translation_value(variants[0], "price", [selected_language]) }}
                    <span v-if="!is_currency_symbol(currency_sign)">{{ currency_sign }}</span>
                  </p>
                </div>
              </div>
            </router-link>
            <p v-if="!found_products.length && query.length > MIN_SEARCH_CHARACTERS">{{ correct_translations.no_products || $translate("header.no_products") }}</p>
          </div>
        </div>
        <div v-if="show_back_button" class="mobile-menu__back" @click="go_back">
          <arrow />
          {{ correct_back_button_text }}
        </div>
        <div
          class="mobile-menu__items"
          :class="{
            'mobile-menu__items--no-top-padding': show_back_button,
            'mobile-menu__items--admin': admin_imported,
            'mobile-menu__items--guide': inside_user_guide,
          }"
        >
          <div
            v-for="({ link, title, click, name, target, children, id, category, image, sub_category }, index) in items"
            :key="`mobile-item-${index}`"
            class="mobile-menu__item"
            :class="{ 'element-loading': !title && !name }"
          >
            <router-link
              v-if="link"
              :to="link"
              :target="target || '_self'"
              :disabled="admin_imported"
              :event="admin_imported ? '' : 'click'"
              @click.native="$emit('handle_click')"
            >
              <p>{{ title || name }}</p>
            </router-link>
            <p v-else @click="() => {
              if (click) {
                click()
                update_collection(
                  id || sub_category,
                  category,
                  image,
                )
              }
            }">
              {{ title || name }} <arrow  v-if="children"/>
            </p>
          </div>
        </div>
        <div
          class="mobile-menu__extra-content"
          :class="{
            'mobile-menu__extra-content--open': show_extra_contents,
            'mobile-menu__extra-content--image': !show_preview_products && collection_image
          }"
        >
          <preview_products
            v-if="show_preview_products"
            :product_ids="show_popular_products ? get_popular_products : preview_products"
            :admin_imported="admin_imported"
            :show_mobile="admin_imported"
          />
          <div
            v-else-if="collection_image"
            class="mobile-menu__collection-image"
            @click="$router.push(`/Products/${selected_collection.category}`)"
          >
            <div>
              <v-lazy-image
                :src="collection_image"
                :alt="get_collection.title"
                itemprop="image"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="mobile-menu-backdrop-slide-animation">
      <div
        v-if="show"
        class="mobile-menu__backdrop"
        :class="{ 'mobile-menu__backdrop--admin': admin_imported }"
        @click="() => { $emit('clear_header_state'); go_back() }"
      />
    </transition>
  </section>
</template>

<script>
import { mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import search_icon from "../../../Shared/components/icon_components/search-icon"
import close_icon from "../../../Shared/components/icon_components/close"
import arrow from "../../../Shared/components/icon_components/arrow"
import search_marketplace_products from "../../../Marketplace/methods/search_marketplace_products"
import debounce from "../../methods/debounce"
import entity_search from "../../methods/entity_search"
import { MARKETPLACE, MIN_SEARCH_CHARACTERS } from "../../../Marketplace/constants/other"
import { currency_signs } from "../../constants/other"
import { CLIENT_STORE } from "../../../Client/constants/other"
import { CONFIGURATION_STORE, CONTENT_STORE, PRODUCTS_STORE } from "../../../Admin/constants/others_constants"
import { default_language } from "../../../../../functions/constans/other"
import un_bind from "../../methods/un_bind"
import preview_products from "../../../Client/components/utils/preview_products"
import get_image_src from "../../methods/get_image_src"
import is_currency_symbol from "../../methods/is_currency_symbol"
import get_correct_translation_value from "../../methods/get_correct_translation_value"
import { image_dimension_names } from "../../../../data/other_constants"

const default_found_products = [{},{},{}]

export default {
  components: {
    search_icon,
    close_icon,
    arrow,
    preview_products,
    VLazyImage
  },
  props: {
    items: {
      type: [Array, Object],
      required: true,
    },
    show_back_button: {
      type: Boolean,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    enable_search: {
      type: Boolean,
      required: false,
      default: true
    },
    local_search: {
      type: Boolean,
      required: false,
      default: false
    },
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    inside_user_guide: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      searching: false,
      found_products: default_found_products,
      query: "",
      selected_collection: {},
      show_extra_contents: false,
      MIN_SEARCH_CHARACTERS
    }
  },
  computed: {
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    ...mapState(CLIENT_STORE, [
      "translations",
      "products",
      "collections",
      "selected_language",
      "popular_products"
    ]),
    ...mapState(CONFIGURATION_STORE, {
      admin_translations: "translations"
    }),
    ...mapState(CONTENT_STORE, {
      admin_popular_products: "popular_products"
    }),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "shopify_collections",
      "imported_products",
      "shopify_products",
    ]),
    ...mapState([
      "is_marketplace_environment",
    ]),
    correct_collections() {
      return this.admin_imported ? [...this.imported_collections, ...this.shopify_collections] : this.collections
    },
    correct_products() {
      return this.admin_imported ? [...(this.imported_products || []), ...(this.shopify_products || [])] : this.products
    },
    get_popular_products() {
      return this.is_marketplace_environment ? [] : this[`${this.admin_imported ? "admin_" : ""}popular_products`].slice(0, 4)
    },
    get_collection() {
      return this.is_marketplace_environment ? {} : this.correct_collections.find(({ id }) => id === this.selected_collection.id) || {}
    },
    collection_image() {
      return this.get_collection?.image || get_image_src(this.get_collection, image_dimension_names.tablet)
    },
    are_items_collections() {
      return Boolean(this.items?.[0]?.id) // Only collections have ID assigned
    },
    available_products() {
      return (
        this.are_items_collections ?
          this.correct_collections.filter(
            ({ id: collection_id }) => this.items.some(({ id }) => id === collection_id)
          ).flatMap(({ products }) => products || []) :
          un_bind(this.get_collection).products
      ) || []
    },
    any_favorite_products() {
      return this.get_collection?.products?.some(({ is_favorite }) => is_favorite)
    },
    preview_products() {
      const products = un_bind(this.available_products)

      products.sort((a = {}, b = {}) => {
        if (a.is_favorite && !b.is_favorite) return -1
        if (b.is_favorite && !a.is_favorite) return 1
        if (b.is_favorite && a.is_favorite) return 0

        return -1
      })

      return products
        .map(({ id }) => id)
        .slice(0, 4)
    },
    correct_translations() {
      const admin_translation = this.admin_translations?.[default_language] ||
        this.admin_translations?.[Object.keys(this.admin_translations)[0]]

      return (this.admin_imported ? admin_translation : this.translations) || {}
    },
    correct_search_placeholder() {
      if (this.admin_imported && !this.correct_translations?.search_title) {
        return this.$translate("user_guide.search")
      }

      return this.correct_translations.search_title || this.$translate('header.search')
    },
    correct_back_button_text() {
      if (this.admin_imported && !this.correct_translations?.back_button) {
        return this.$translate("user_guide.back")
      }

      return this.correct_translations.back_button || this.$translate('header.back')
    },
    currency_sign() {
      return this.translations?.currency_symbol || currency_signs[this.marketplace_currency]
    },
    show_preview_products() {
      return this.selected_collection.id ? (this.any_favorite_products || !this.get_collection.image) : !this.get_collection.id
    },
    show_popular_products() {
      return !this.are_items_collections && !this.selected_collection.id
    },
  },
  watch: {
    show(val) {
      if (!this.admin_imported) document.body.style.overflow = val ? "hidden" : "auto"
    },
  },
  mounted() {
    this.show_extra_contents = this.show_preview_products
  },
  methods: {
    get_image_src,
    is_currency_symbol,
    get_correct_translation_value,
    update_collection(
      id,
      category,
      image
    ) {
      this.selected_collection = {
        id,
        category,
        image
      }
    },
    search_products() {
      if (this.admin_imported) return
      this.searching = true
      this.show_extra_contents = false
      this.found_products = this.query.length > MIN_SEARCH_CHARACTERS ? default_found_products : []

      debounce(async () => {
        if (this.query.length > MIN_SEARCH_CHARACTERS) {
          const products = this.local_search ? entity_search(this.correct_products, this.query) : await search_marketplace_products({ query: this.query })
          this.$nextTick(() => this.found_products = products)
        }
      }, 1000, this)()
    },
    stop_searching() {
      this.searching = false
      this.query = ""
      this.found_products = default_found_products
      this.show_extra_contents = true
    },
    toggle_extra_contents() {
      this.show_extra_contents = !this.show_extra_contents
    },
    go_back() {
      this.$emit('back')
      this.toggle_extra_contents()
      this.selected_collection = {}
      this.show_extra_contents = this.show_preview_products
    },
    has_collection_favorite_products(collection_id) {
      return (this.correct_collections.find(({ id }) => id === collection_id) || {}).products?.some(({ is_favorite }) => is_favorite)
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/element_loading.scss";
  @use "../../../../styles/search.scss" as *;

  .mobile-menu {
    position: absolute;
    left: -5px;
    top: $menu-height;
    height: calc(100vh - #{$menu-height});
    width: calc(100vw - #{$menu-height} - 3px);
    max-width: $max-mobile-menu-width;
    background-color: var(--body_color);
    color: $grey--dark;
    border-top: 1px solid $grey--light;
    line-height: normal;

    $back-height: 55px;
    $admin-top-height: 68px;

    &__wrapper {
      display: none;

      &--admin {
        display: block;
      }

      @media (max-width: $tablet) {
        display: block;
      }
    }

    &--admin {
      width: 80%;
      height: calc(100vh - #{$menu-height} - #{$admin-top-bar-height} - #{$admin-top-height});
    }

    &--client {
      p {
        color: var(--font_heavy);
      }
    }

    &__back {
      display: flex;
      align-items: baseline;
      padding: 0 0 0 $spacing--normal;
      font-size: $font-size--medium-large;
      line-height: $back-height;
      color: var(--background_heavy);
      cursor: pointer;

      svg {
        width: $font-size--small;
        height: $font-size--small;
        fill: var(--background_heavy);
        transform: rotate(180deg);
        margin-right: $spacing--tiny;
      }
    }

    &__item {
      cursor: pointer;
      font-size: $font-size--medium-large;

      p {
        margin: 0;
        font-size: 20px;
        padding: $spacing--tiny 0;
      }

      svg {
        width: $spacing--small;
        height: $spacing--small;
        float: right;
        margin-top: 5px;
        fill: var(--background_heavy);
      }
    }

    &__items {
      position: relative;
      padding: $spacing--small $spacing--normal $spacing--normal;
      height: calc(100vh - #{$search-height + $menu-height + $max-mobile-menu-width + $spacing--normal});
      overflow-y: auto;

      &--admin {
        height: calc(100vh - #{$search-height + $menu-height + $max-mobile-menu-width + $spacing--normal + $admin-top-height});
      }

      &--guide {
        height: auto;
      }

      &:after {
        content: "";
        position: sticky;
        bottom: -20px;
        width: 100%;
        height: 20px;
        background: linear-gradient(0deg, var(--body_color) 9%, transparent 76%);;
        left: 0;
        display: block;
        right: 0;
      }

      @media (max-width: $max-mobile-menu-width) {
        height: calc(100vh - #{$search-height + $menu-height + $spacing--normal} - 100vw + #{$menu-height} + 3px);
      }

      &--no-top-padding {
        height: calc(100vh - #{$search-height + $menu-height + $max-mobile-menu-width + $back-height + $spacing--normal});
        padding: 0 $spacing--normal $spacing--normal;

        &.mobile-menu__items--admin {
          height: calc(100vh - #{$search-height + $menu-height + $max-mobile-menu-width + $back-height + $spacing--normal + $admin-top-height});
        }

        &.mobile-menu__items--guide {
          height: auto;
        }

        @media (max-width: $max-mobile-menu-width) {
          height: calc(100vh - #{$search-height + $menu-height + $back-height + $spacing--normal} - 100vw + #{$menu-height} + 3px);
        }
      }
    }

    input {
      font-family: 'Helvetica Neue', sans-serif;

      &::placeholder {
        font-family: 'Helvetica Neue', sans-serif;
        text-align: left;
      }
    }

    &__extra-content {
      position: absolute;
      bottom: $spacing--normal;
      left: 0;
      width: 100%;
      padding: 0 $spacing--normal;
      padding-top: $spacing--normal;

      &--image {
        &:before {
          position: absolute;
          content: "";
          width: calc(100% - 2 * #{$spacing--normal});
          height: 1px;
          background: var(--background_heavy);
          top: -#{$spacing--small};
          left: $spacing--normal;
          opacity: 0.5;
        }
      }

      &:after {
        display: block;
        content: "";
        padding-bottom: 100%;
      }
    }

    &__collection-image {
      display: block;
      position: absolute;
      top: 0;
      left: $spacing--normal;
      right: $spacing--normal;
      height: 100%;
      border-radius: $border-radius;
      overflow: hidden;

      div, img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% !important;
      }

      div {
        clip-path: polygon(10% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%);
      }

      &:after {
        display: block;
        content: "";
        padding-bottom: 100%;
      }
    }

    &__backdrop {
      position: fixed;
      top: $menu-height;
      right: 0;
      z-index: -1;
      background-color: $shadow-color;
      width: 100vw;
      height: calc(100vh - #{$menu-height});

      &--admin {
        position: absolute;
        right: -5px;
      }
    }

    &-slide-animation-enter-active, &-slide-animation-leave-active {
      transition: opacity .3s ease-in-out, transform .3s ease-in-out;
      transform: translateX(0);
    }
    &-slide-animation-enter, &-slide-animation-leave-to {
      transform: translateX(-100%);
    }

    &-backdrop-slide-animation-enter-active, &-backdrop-slide-animation-leave-active {
      opacity: 1;
      transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    }
    &-backdrop-slide-animation-enter, &-backdrop-slide-animation-leave-to {
      opacity: 0;
    }
  }
</style>
